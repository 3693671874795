/*
 * Ryan O'Dowd
 * 2021-05-24
 * © Copyright 2021 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Checkbox,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  addNewSection,
  createAdmin,
  createInstructor,
  createPartnerAgreementObserver,
  fetchInstructors,
  fetchObserverPartnerAgreements,
  getTableColumnNames,
  insertDatabaseRecord,
  resetFacultyPassword,
  updateDatabaseRecord,
  updateInstructor,
} from '../../../actions';
import {
  connect,
  useDispatch,
  useSelector,
} from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PropTypes from 'prop-types';
import ReorderIcon from '@mui/icons-material/Reorder';
import {
  bindActionCreators,
} from 'redux';
import styles from './styles';

const DatabaseTools = (props) => {
  const [_tableName, setTableName] = useState('enrollments');
  const [_columnName, setColumnName] = useState('');
  const [_columnValue, setColumnValue] = useState('');
  const [_recordId, setRecordId] = useState('');
  const [_canvasCourseId, setCanvasCourseId] = useState('');
  const [_insertValues, setInsertValues] = useState('');
  const [_isNullValue, setIsNullValue] = useState(false);
  const [_isColumnCurrentTimestamp, setIsColumnCurrentTimestamp] = useState(false);
  const [_isColumnValueRequired, setIsColumnValueRequired] = useState(true);
  const [_selectedInstitutionId, setSelectedInstitutionId] = useState(null);
  const [_institutionAgreement, setInstitutionAgreement] = useState(null);
  const [_observerFirstName, setObserverFirstName] = useState(null);
  const [_observerLastName, setObserverLastName] = useState(null);
  const [_observerEmail, setObserverEmail] = useState(null);
  const [_instructorId, setInstructorId] = useState('');
  const [_instructorTitle, setInstructorTitle] = useState('');
  const [_instructorFirstName, setInstructorFirstName] = useState('');
  const [_instructorMiddleName, setInstructorMiddleName] = useState('');
  const [_instructorLastName, setInstructorLastName] = useState('');
  const [_instructorSuffix, setInstructorSuffix] = useState('');
  const [_instructorDisplayName, setInstructorDisplayName] = useState('');
  const [_instructorEmail, setInstructorEmail] = useState('');
  const [_instructorBio, setInstructorBio] = useState('');
  const [_instructorFullTime, setInstructorFullTime] = useState('');
  const [_instructorSupport, setInstructorSupport] = useState('');
  const [_instructorJobBand, setInstructorJobBand] = useState('');
  const [_instructorPercentage, setInstructorPercentage] = useState('');
  const [_instructorPaylocityId, setInstructorPaylocityId] = useState('');
  const [_instructorDegree, setInstructorDegree] = useState('');
  const [_instructorDegreeArray, setInstructorDegreeArray] = useState([]);
  const [_adminFirstName, setAdminFirstName] = useState('');
  const [_adminLastName, setAdminLastName] = useState('');
  const [_adminEmail, setAdminEmail] = useState('');
  const [_adminFullTime, setAdminFullTime] = useState('');
  const [_adminDepartment, setAdminDepartment] = useState('');
  const [_draggingItem, setDraggingItem] = useState(null);
  const partnerAgreements = useSelector((state) => state.observerPartnerAgreements);
  const columnNames = useSelector((state) => state.tableColumnNames);
  const instructors = useSelector((state) => state.instructors);
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/,><\\':;|_~`])\S{8,99}$/;
  const [_facultyPassword, setFacultyPassword] = useState('');
  const [_facultyConfirmPassword, setFacultyConfirmPassword] = useState('');
  const [_facultyPasswordError, setFacultyPasswordError] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTableColumnNames(_tableName));
  }, [dispatch, _tableName]);

  useEffect(() => {
    dispatch(fetchObserverPartnerAgreements());
  }, []);

  useEffect(() => {
    dispatch(fetchInstructors());
  }, [dispatch, _instructorId]);

  useEffect(() => {
    let instructor;
    if (Object.values(instructors).length > 0) {
      instructor = instructors.find((inst) => inst.id === _instructorId);
    }

    if (_instructorId === '') {
      _setInstructorValuesToEmptyString();
    }
    if (instructor !== undefined) {
      setInstructorTitle(instructor.title);
      setInstructorFirstName(instructor.first_name);
      setInstructorMiddleName(instructor.middle_name);
      setInstructorLastName(instructor.last_name);
      setInstructorSuffix(instructor.suffix);
      setInstructorEmail(instructor.email);
      setInstructorDisplayName(instructor.website_display_name);

      if (instructor.full_time === null) {
        setInstructorFullTime('');
      } else {
        setInstructorFullTime(instructor.full_time);
      }

      if (instructor.support === null) {
        setInstructorSupport('');
      } else {
        setInstructorSupport(instructor.support);
      }

      if (instructor.job_band_reference_id === null) {
        setInstructorJobBand('');
      } else {
        setInstructorJobBand(instructor.job_band_reference_id);
      }

      if (instructor.percentage === null) {
        setInstructorPercentage('');
      } else {
        setInstructorPercentage(instructor.percentage);
      }

      if (instructor.paylocity_id === null) {
        setInstructorPaylocityId('');
      } else {
        setInstructorPaylocityId(instructor.paylocity_id);
      }
    }
  }, [_instructorId, instructors]);

  const _handleFacultyResetPassword = () => {
    if (_facultyPassword.length === 0 || !passwordRegex.test(_facultyPassword)) {
      setFacultyPasswordError('Please make sure the password meets criteria.');
    } else if (_facultyConfirmPassword !== _facultyPassword) {
      setFacultyPasswordError('Please make sure your confirmation password is the same.');
    } else {
      dispatch(resetFacultyPassword(_facultyPassword));
      setFacultyPasswordError('');
      setFacultyConfirmPassword('');
      setFacultyPassword('');
    }
  };

  const _returnColumnNameOptions = () => {
    return (columnNames.map((colName) => { return <MenuItem key={colName} value={colName}>{colName}</MenuItem>; }));
  };

  const _handleDragStart = (e, item) => {
    setDraggingItem(item);
    e.dataTransfer.setData('text/plain', '');
  };

  const _handleDragEnd = () => {
    setDraggingItem(null);
  };

  const _handleDragOver = (e) => {
    e.preventDefault();
  };

  const _handleDrop = (e, targetItem) => {
    const draggingItem = _draggingItem;
    const instructorDegrees = _instructorDegreeArray;
    if (!draggingItem) return;

    const currIndex = instructorDegrees.indexOf(draggingItem);
    const targetIndex = instructorDegrees.indexOf(targetItem);

    if (currIndex !== -1 && targetIndex !== -1) {
      instructorDegrees.splice(currIndex, 1);
      instructorDegrees.splice(targetIndex, 0, draggingItem);
      setInstructorDegreeArray(instructorDegrees);
    }

    setInstructorDegreeArray(_instructorDegreeArray.map((degree, index) => ({position: index + 1, value: degree.value})));
  };

  const addNewDegree = () => {
    let nextDegreePosition = 1;
    if (_instructorDegreeArray.length !== 0) {
      nextDegreePosition = Math.max(..._instructorDegreeArray.map((item) => item.position)) + 1;
    }
    _instructorDegreeArray.push({
      position: nextDegreePosition,
      value: _instructorDegree,
    });
  };

  const _deleteDegree = (e, degree) => {
    let newDegreeArray = _instructorDegreeArray;
    newDegreeArray = newDegreeArray.filter(function(filteredDegree) {
      return filteredDegree.value !== degree.value;
    });
    newDegreeArray = newDegreeArray.map((degree, index) => ({position: index + 1, value: degree.value}));
    setInstructorDegreeArray(newDegreeArray);
  };

  const _returnInstructorObject = () => {
    return {
      id: _instructorId,
      title: _instructorTitle,
      first_name: _instructorFirstName,
      middle_name: _instructorMiddleName,
      last_name: _instructorLastName,
      suffix: _instructorSuffix,
      email: _instructorEmail,
      website_display_name: _instructorDisplayName,
      bio: _instructorBio,
      degrees: _instructorDegreeArray,
      full_time: _instructorFullTime,
      support: _instructorSupport,
      job_band_reference_id: _instructorJobBand,
      percentage: _instructorPercentage,
      paylocity_id: _instructorPaylocityId,
    };
  };

  const _returnAdminObject = () => {
    return {
      first_name: _adminFirstName,
      last_name: _adminLastName,
      email: _adminEmail,
      full_time: _adminFullTime,
      department: _adminDepartment
    };
  };

  const _setInstructorValuesToEmptyString = () => {
    setInstructorId('');
    setInstructorTitle('');
    setInstructorMiddleName('');
    setInstructorSuffix('');
    setInstructorBio('');
    setInstructorFirstName('');
    setInstructorLastName('');
    setInstructorEmail('');
    setInstructorDegree('');
    setInstructorDegreeArray([]);
    setInstructorPaylocityId('');
    setInstructorFullTime('');
    setInstructorSupport('')
    setInstructorJobBand('');
    setInstructorPercentage('');
    setInstructorDisplayName('');
  };

  const _setAdminValuesToEmptyString = () => {
    setAdminFirstName('');
    setAdminLastName('');
    setAdminEmail('');
    setAdminFullTime('');
    setAdminDepartment('');
  };

  return (
    <div style={styles.container}>
      <h3>Update database records</h3>
      <div style={styles.inputContainer}>
        <p>UPDATE</p>
        <div style={styles.input}>
          <InputLabel htmlFor='table_name'>Table</InputLabel>
          <Select
            disableUnderline={true}
            value={_tableName}
            onChange={(e) => setTableName(e.target.value)}
            input={<Input id='table_name' name='table_name' />}
          >
            <MenuItem key='enrollments' value='enrollments'>enrollments</MenuItem>
            <MenuItem key='students' value='students'>students</MenuItem>
          </Select>
        </div>
        <p>SET</p>
        <div style={styles.input}>
          <InputLabel htmlFor='column_name'>Column Name</InputLabel>
          <Select
            disableUnderline={true}
            value={_columnName}
            onChange={(e) => setColumnName(e.target.value)}
            input={<Input id='column_name' name='column_name' />}
            required={true}
            style={styles.formControl}
          >
            {_returnColumnNameOptions()}
          </Select>
        </div>
        <p>=</p>
        <TextField
          label='Column value'
          variant='outlined'
          value={_columnValue}
          onChange={(e) => setColumnValue(e.target.value)}
          required={_isColumnValueRequired}
          disabled={_isNullValue || _isColumnCurrentTimestamp}
        />
        <p>WHERE ID =</p>
        <TextField
          label='Record ID'
          variant='outlined'
          value={_recordId}
          onChange={(e) => setRecordId(e.target.value)}
          required={true}
        />
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <span>
            <Checkbox
              value={_isNullValue}
              onChange={(e) => {
                setIsNullValue(e.target.checked);
                setIsColumnValueRequired(!e.target.checked);
              }}
              required={false}
              disabled={_isColumnCurrentTimestamp}
            />
            Set Column Value as Null
          </span>
          <span>
            <Checkbox
              value={_isColumnCurrentTimestamp}
              onChange={(e) => {
                setIsColumnCurrentTimestamp(e.target.checked);
                setIsColumnValueRequired(!e.target.checked);
                setColumnValue(new Date().toISOString().slice(0, 19));
              }}
              required={false}
              disabled={_isNullValue}
            />
            Set Column Value to Current Timestamp
          </span>
        </div>
      </div>
      <div>
        <Button
          variant='contained'
          disabled={!_recordId}
          color='secondary'
          onClick={() => props.updateDatabaseRecord(_tableName, _columnName, _columnValue, _recordId, _isNullValue)}
        >
          Update Record to DB
        </Button>
      </div>
      <span style={styles.divisionLine}></span>
      <div style={{marginTop: 50}}>
        <h3>Add new Canvas section</h3>
        <div style={styles.canvasSectionContainer} >
          <TextField
            style={{marginRight: 8}}
            label='Canvas course ID'
            value={_canvasCourseId}
            onChange={(e) => setCanvasCourseId(e.target.value)}
            margin='dense'
            required={true}
          />
          <Button
            variant='contained'
            disabled={!_canvasCourseId}
            color='secondary'
            onClick={() => props.addNewSection(_canvasCourseId)}
          >
            Create Canvas Section
          </Button>
        </div>
      </div>
      <span style={styles.divisionLine}></span>
      <div style={{marginTop: 50}}>
        <h3>Add New Place</h3>
        <div style={styles.inputContainer}>
          <p>INSERT INTO</p>
          <TextField
            disabled={true}
            label='Table'
            value='places'
            variant='outlined'
            onChange={(e) => setTableName(e.target.value)}
            margin='dense'
          />
          <p>VALUES</p>
          <TextField
            fullWidth
            label='(title, email_to, contact_name_1, address, city, state, zip, address2, url, date_modified)'
            multiline
            value={_insertValues}
            onChange={(e) => setInsertValues(e.target.value)}
            margin='dense'
            variant='outlined'
            required={true}
          />
        </div>
        <Button
          style={{marginTop: '10px'}}
          variant='contained'
          disabled={!_insertValues}
          color='secondary'
          onClick={() => props.insertDatabaseRecord('places', _insertValues)}
        >
          Create New Place
        </Button>
      </div>
      <span style={styles.divisionLine}></span>
      <div style={{marginTop: 50}}>
        <h3>Create Observer</h3>
        <div style={styles.observerContainer}>
          <div style={styles.inputContainer}>
            <div style={styles.input}>
              <InputLabel>Institution</InputLabel>
              <Select
                disableUnderline={true}
                value={_selectedInstitutionId}
                onChange={(e) => {
                  setSelectedInstitutionId(e.target.value);
                }}
                style={{
                  minWidth: 250,
                  width: 'fit-content',
                }}
              >
                {Object.keys(partnerAgreements).length > 0 && partnerAgreements.map((institution) =>
                  <MenuItem key={institution.id} value={institution.id}>{institution.name}</MenuItem>
                )}
              </Select>
            </div>
            <div style={styles.input}>
              <InputLabel>Institution Agreement</InputLabel>
              <Select
                disableUnderline={true}
                value={_institutionAgreement}
                onChange={(e) => {
                  setInstitutionAgreement(e.target.value);
                }}
                disabled={!_selectedInstitutionId}
                style={{width: 250}}
              >
                {Object.keys(partnerAgreements).length > 0 && partnerAgreements.filter((institution) => institution.id === _selectedInstitutionId).map((filteredInstitution) =>
                  filteredInstitution.partner_agreements.map((agreement) => {
                    return (<MenuItem key={agreement.id} value={agreement.id}>{agreement.type}</MenuItem>);
                  })
                )
                }
              </Select>
            </div>
          </div>
          <div style={styles.inputContainer}>
            <div style={styles.input}>
              <InputLabel>First Name</InputLabel>
              <Input
                disableUnderline={true}
                id='observer_first_name'
                name='observer_first_name'
                onChange={(e) => setObserverFirstName(e.target.value)}
              />
            </div>
            <div style={styles.input}>
              <InputLabel>Last Name</InputLabel>
              <Input
                disableUnderline={true}
                id='observer_last_name'
                name='observer_last_name'
                onChange={(e) => setObserverLastName(e.target.value)}
              />
            </div>
            <div style={styles.input}>
              <InputLabel>Email</InputLabel>
              <Input
                disableUnderline={true}
                id='observer_email'
                name='observer_emailserver_last_name'
                style={{marginRight: 20, width: 450}}
                onChange={(e) => setObserverEmail(e.target.value)}
              />
            </div>
          </div>
          <Button
            variant='contained'
            color='secondary'
            disabled={!_observerFirstName || !_observerLastName || !_observerEmail || !_institutionAgreement}
            onClick={() => {
              setObserverFirstName(null);
              setObserverLastName(null);
              setObserverEmail(null);
              setSelectedInstitutionId(null);
              setInstitutionAgreement(null);

              dispatch(createPartnerAgreementObserver(_institutionAgreement, {
                first_name: _observerFirstName,
                last_name: _observerLastName,
                email: _observerEmail,
              }));
            }}
            style={{width: 'fit-content'}}
          >
            Create Observer
          </Button>
        </div>
      </div>
      <span style={styles.divisionLine}></span>
      <div style={{marginTop: 50}}>
        <h3>Create/Update Instructor</h3>
        <p>To Create: Fill out all fields marked with * and any other fields, press Create Instructor button</p>
        <p>To Update: Enter Instructor ID and any other fields to update, press Update Instructor button</p>
        <div style={styles.instructorContainer}>
          <div style={styles.inputContainer}>
            <div style={styles.input}>
              <InputLabel>Instructor ID</InputLabel>
              <Select
                disableUnderline={true}
                id='instructor_id'
                name='instructor_id'
                value={_instructorId}
                onChange={(e) => {
                  setInstructorId(e.target.value);
                  setInstructorBio('');
                  setInstructorDegreeArray([]);
                }}
              >
                {instructors.length > 0 && instructors.map((instructor) =>
                  <MenuItem key={instructor.id} value={instructor.id}>{instructor.first_name} {instructor.last_name}</MenuItem>
                )}
                <MenuItem key={''} value={''}>No Instructor</MenuItem>
              </Select>
            </div>
          </div>
          <div style={styles.inputContainer}>
            <div style={styles.input}>
              <InputLabel>*Title</InputLabel>
              <Select
                disableUnderline={true}
                value={_instructorTitle}
                onChange={(e) => setInstructorTitle(e.target.value)}
                input={<Input id='instructor_title' name='instructor_title' />}
              >
                <MenuItem key='Professor' value='Professor'>Professor</MenuItem>
                <MenuItem key='Dr.' value='Dr.'>Dr.</MenuItem>
              </Select>
            </div>
            <div style={styles.input}>
              <InputLabel>*First Name</InputLabel>
              <Input
                disableUnderline={true}
                id='instructor_first_name'
                name='instructor_first_name'
                value={_instructorFirstName}
                onChange={(e) => setInstructorFirstName(e.target.value)}
              />
            </div>
            <div style={styles.input}>
              <InputLabel>Middle Name</InputLabel>
              <Input
                disableUnderline={true}
                id='instructor_middle_name'
                name='instructor_middle_name'
                value={_instructorMiddleName}
                onChange={(e) => setInstructorMiddleName(e.target.value)}
              />
            </div>
            <div style={styles.input}>
              <InputLabel>*Last Name</InputLabel>
              <Input
                disableUnderline={true}
                id='instructor_last_name'
                name='instructor_last_name'
                value={_instructorLastName}
                onChange={(e) => setInstructorLastName(e.target.value)}
              />
            </div>
            <div style={styles.input}>
              <InputLabel>Suffix</InputLabel>
              <Input
                disableUnderline={true}
                id='instructor_suffix'
                name='instructor_suffix'
                style={{width: 75}}
                value={_instructorSuffix}
                onChange={(e) => setInstructorSuffix(e.target.value)}
              />
            </div>
            <div style={styles.input}>
              <InputLabel>*Email</InputLabel>
              <Input
                disableUnderline={true}
                id='instructor_email'
                name='instructor_email'
                style={{marginRight: 20, width: 450}}
                value={_instructorEmail}
                onChange={(e) => setInstructorEmail(e.target.value)}
              />
            </div>
          </div>
          <div style={styles.inputContainer}>
            <div style={styles.input}>
              <InputLabel>*Full Time (boolean)</InputLabel>
              <Input
                disableUnderline={true}
                id="instructor_full_time"
                name="instructor_full_time"
                value={_instructorFullTime}
                onChange={(e) => setInstructorFullTime(e.target.value)}
              />
            </div>
            <div style={styles.input}>
              <InputLabel>*Support (boolean)</InputLabel>
              <Input
                disableUnderline={true}
                id="instructor_support"
                name="instructor_support"
                value={_instructorSupport}
                onChange={(e) => setInstructorSupport(e.target.value)}
              />
            </div>
            <div style={styles.input}>
              <InputLabel>Job Band ID</InputLabel>
              <Select
                disableUnderline={true}
                value={_instructorJobBand}
                onChange={(e) => setInstructorJobBand(e.target.value)}
                input={<Input id="instructor_job_band" name="instructor_job_band"/>}
              >
                <MenuItem key={null} value={null}>Null</MenuItem>
                <MenuItem key="2" value="2">2</MenuItem>
                <MenuItem key="3" value="3">3</MenuItem>
                <MenuItem key="4" value="4">4</MenuItem>
                <MenuItem key="5" value="5">5</MenuItem>
              </Select>
            </div>
            <div style={styles.input}>
              <InputLabel>Percentage</InputLabel>
              <Input
                disableUnderline={true}
                id="instructor_percentage"
                name="instructor_percentage"
                value={_instructorPercentage}
                onChange={(e) => setInstructorPercentage(e.target.value)}
              />
            </div>
            <div style={styles.input}>
              <InputLabel>Paylocity ID</InputLabel>
              <Input
                disableUnderline={true}
                id="instructor_paylocity_id"
                name="instructor_paylocity_id"
                value={_instructorPaylocityId}
                onChange={(e) => setInstructorPaylocityId(e.target.value)}
              />
            </div>
            <div style={styles.input}>
              <InputLabel>*Website Display Name</InputLabel>
              <Input
                disableUnderline={true}
                id="instructor_display_name"
                name="instructor_display_name"
                style={{width: 300}}
                value={_instructorDisplayName}
                onChange={(e) => setInstructorDisplayName(e.target.value)}
              />
            </div>
          </div>
          {!_instructorId &&
            <div style={styles.metadataContainer}>
              <div style={styles.inputContainer}>
                <div style={styles.input}>
                  <InputLabel>Instructor Bio</InputLabel>
                  <Input
                    disableUnderline={true}
                    id="instructor_bio"
                    name="instructor_bio"
                    style={{width: 975}}
                    value={_instructorBio}
                    onChange={(e) => setInstructorBio(e.target.value)}
                  />
                </div>
              </div>
              <div style={styles.degreeInputContainer}>
                <div style={styles.input}>
                  <InputLabel>Instructor Degree (level, major, year, university)</InputLabel>
                  <Input
                    disableUnderline={true}
                    id='instructor_degree'
                    name='instructor_degree'
                    style={{width: 450}}
                    value={_instructorDegree}
                    onChange={(e) => setInstructorDegree(e.target.value)}
                  />
                </div>
                <Button
                  variant='contained'
                  color='secondary'
                  disabled={!_instructorDegree}
                  onClick={() => {
                    setInstructorDegree('');
                    addNewDegree();
                  }}
                >
                  Add Degree
                </Button>
              </div>
              <div style={styles.degreeDisplayContainer}>
                <div>
                  <h6>Instructor Degrees Taken:</h6>
                  {_instructorDegreeArray.map((degree, index) =>
                    <div
                      style={styles.degreeList}
                      key={degree.position}
                      className=
                        {`item ${degree === _draggingItem
                          ? 'dragging' : ''
                        }`}
                      draggable='true'
                      onDragStart={(e) => _handleDragStart(e, degree)}
                      onDragEnd={_handleDragEnd}
                      onDragOver={_handleDragOver}
                      onDrop={(e) => _handleDrop(e, degree)}
                    >
                      <div style={styles.degreeCard}>
                        <span>{degree.position}.</span>
                        <span>{degree.value}</span>
                        <ReorderIcon/>
                        <DeleteForeverIcon
                          onClick={(e) => _deleteDegree(e, degree)}
                        ></DeleteForeverIcon>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          }
          <div style={styles.buttonContainer}>
            <Button
              variant='contained'
              color='secondary'
              disabled={(!_instructorTitle || !_instructorFirstName || !_instructorLastName || !_instructorEmail || !_instructorFullTime || !_instructorSupport || !_instructorDisplayName) || _instructorId}
              onClick={() => {
                _setInstructorValuesToEmptyString();
                dispatch(createInstructor(_returnInstructorObject()));
              }}
              style={{width: 'fit-content'}}
            >
            Create Instructor
            </Button>
            <Button
              variant='contained'
              color='secondary'
              disabled={!_instructorId}
              onClick={() => {
                _setInstructorValuesToEmptyString();
                dispatch(updateInstructor(_returnInstructorObject()));
              }}
              style={{width: 'fit-content'}}
            >
            Update Instructor
            </Button>
          </div>
        </div>
      </div>
      <span style={styles.divisionLine}></span>
      <div style={{marginTop: 50}}>
        <h3>Update The Faculty Password</h3>
        <p>This will reset the password for all Faculty.</p>
        <p>Password must be at least 8 characters and include at least one lower-case letter, one upper-case letter, one number, and one special character.</p>
        <p>It also must not contain +, -, =, or a space.</p>
        <div style={styles.instructorContainer}>
          <div style={styles.inputContainer}>
            <div style={styles.input}>
              <InputLabel>*Password</InputLabel>
              <Input
                disableUnderline={true}
                id='faculty_password'
                name='faculty_password'
                type={'password'}
                value={_facultyPassword}
                onChange={(e) => setFacultyPassword(e.target.value)}
              />
            </div>
            <div style={styles.input}>
              <InputLabel>*Confirm Password</InputLabel>
              <Input
                disableUnderline={true}
                id='faculty_confirm_password'
                name='faculty_confirm_password'
                type={'password'}
                value={_facultyConfirmPassword}
                onChange={(e) => setFacultyConfirmPassword(e.target.value)}
              />
            </div>
          </div>
          {_facultyPasswordError !== '' &&
            <p style={styles.passwordError}>{_facultyPasswordError}</p>
          }
          <div style={styles.buttonContainer}>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => {
                _handleFacultyResetPassword();
              }}
              style={{width: 'fit-content'}}
            >
              Reset Password
            </Button>
          </div>
        </div>
      </div>
      <span style={styles.divisionLine}></span>
      <div style={{marginTop: 50}}>
        <h3>Create Admin</h3>
        <div style={styles.instructorContainer}>
          <div style={styles.inputContainer}>
            <div style={styles.input}>
              <InputLabel>First Name</InputLabel>
              <Input
                disableUnderline={true}
                id='admin_first_name'
                name='admin_first_name'
                value={_adminFirstName}
                onChange={(e) => setAdminFirstName(e.target.value)}
              />
            </div>
            <div style={styles.input}>
              <InputLabel>Last Name</InputLabel>
              <Input
                disableUnderline={true}
                id='admin_last_name'
                name='admin_last_name'
                value={_adminLastName}
                onChange={(e) => setAdminLastName(e.target.value)}
              />
            </div>
            <div style={styles.input}>
              <InputLabel>Email</InputLabel>
              <Input
                disableUnderline={true}
                id='admin_email'
                name='admin_email'
                style={{marginRight: 20, width: 450}}
                value={_adminEmail}
                onChange={(e) => setAdminEmail(e.target.value)}
              />
            </div>
            <div style={styles.input}>
              <InputLabel>Schedule</InputLabel>
              <Select
                disableUnderline={true}
                value={_adminFullTime}
                onChange={(e) => setAdminFullTime(e.target.value)}
                input={<Input id='admin_full_time' name='admin_full_time' />}
              >
                <MenuItem key='Full Time' value='1'>Full Time</MenuItem>
                <MenuItem key='Part Time' value='0'>Part Time</MenuItem>
              </Select>
            </div>
            <div style={styles.input}>
              <InputLabel>Department</InputLabel>
              <Select
                disableUnderline={true}
                value={_adminDepartment}
                onChange={(e) => setAdminDepartment(e.target.value)}
                input={<Input id='admin_department' name='admin_department' />}
              >
                <MenuItem key='Academics' value='Academics'>Academics</MenuItem>
                <MenuItem key='Administration' value='Administration'>Administration</MenuItem>
                <MenuItem key='Engineering' value='Engineering'>Engineering</MenuItem>
                <MenuItem key='Finance' value='Finance'>Finance</MenuItem>
                <MenuItem key='IT' value='IT'>IT</MenuItem>
                <MenuItem key='Marketing' value='Marketing'>Marketing</MenuItem>
                <MenuItem key='Multimedia' value='Multimedia'>Multimedia</MenuItem>
                <MenuItem key='School Relations' value='School Relations'>School Relations</MenuItem>
                <MenuItem key='Student Services' value='Student Services'>Student Services</MenuItem>
                <MenuItem key='Faculty Dept Chair' value='Faculty Dept Chair'>Faculty Dept Chair</MenuItem>
              </Select>
            </div>
          </div>
          <div style={styles.buttonContainer}>
            <Button
              variant='contained'
              color='secondary'
              disabled={!_adminFirstName || !_adminLastName || !_adminEmail || !_adminFullTime || !_adminDepartment}
              onClick={() => {
                dispatch(createAdmin(_returnAdminObject(),
                  _setAdminValuesToEmptyString
                ));
              }}
              style={{width: 'fit-content'}}
            >
            Create Admin
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

DatabaseTools.propTypes = {
  addNewSection: PropTypes.func.isRequired,
  insertDatabaseRecord: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  updateDatabaseRecord: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addNewSection,
    insertDatabaseRecord,
    updateDatabaseRecord,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DatabaseTools);
