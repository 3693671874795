/*
 * Ally Zernick
 * 2024-02-22
 * © Copyright 2023 NursingABC, Inc.  All Rights Reserved.
 */
import DangerousIcon from '@mui/icons-material/Dangerous';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Tooltip,
} from '@material-ui/core';

const HasViolation = (props) => {
  const colorKey = {
    1: '#641E16',
    2: '#D35400',
    3: '#D4AC0D',
    4: '#145A32',
    5: '#154360',
    6: '#512E5F',
  };
  const typeKey = {
    1: 'TAIW',
    2: 'TAI',
    3: 'AV-T1',
    4: 'AV-T2 (On Camera)',
    5: 'AV-T2 (Dishonesty)',
    6: 'AV-T3',
  };

  return (
    props.severity.map((e, i) => {
      return (
        <Tooltip key={i} title={`${typeKey[e]} Violation Found`}>
          <DangerousIcon style={{color: colorKey[e]}} />
        </Tooltip>
      );
    })
  );
};

HasViolation.propTypes = {
  severity: PropTypes.array.isRequired,
};

export default HasViolation;
