/**
 *
 * Ryan O'Dowd
 * 2018-12-17
 * © Copyright 2018 NursingABC, Inc. All Rights Reserved.
 */
import {
  ADD_KNOWN_SCHOOL,
  ADD_REGISTRATION_DATA,
  ADD_STUDENTS,
  ADD_UNASSIGNED_STUDENT_IDS,
  CAMPUSES_WITH_PROGRESS_REPORT_STUDENTS,
  CLEAR_FORCE_CONCLUDE_STUDENT_COURSE_STATE,
  CLEAR_MERGE_STUDENT_FLAG,
  CLEAR_S3_SHARE_LINK,
  CLEAR_TRANSCRIPT_HISTORY,
  CLEAR_TRANSCRIPT_REQUESTS,
  CREATE_ELECTRONIC_TRANSCRIPT_TRANSFER_S3_COGNITO_ACCOUNT,
  CREATE_PARTNER_SCHOOL,
  EXTEND_STUDENT_QUIZ_TIME,
  FETCH_ACADEMIC_SCHOLARSHIP_REPORT,
  FETCH_ACTIVE_ENROLLMENTS_FOR_INSTRUCTOR,
  FETCH_BLUEPRINT_COURSES,
  FETCH_BLUEPRINT_COURSES_BY_COURSE_CODE,
  FETCH_COURSES_FOR_DEPT_CHAIR,
  FETCH_ERROR,
  FETCH_ERROR_OBJECT,
  FETCH_NABC_CANVAS_COURSE_POINTS,
  FETCH_PARTNER_SCHOOLS,
  FETCH_REQUEST,
  FETCH_REQUEST_OBJECT,
  FETCH_SUCCESS_OBJECT,
  FETCH_TRANSCRIPT_REQUESTS,
  FETCH_TRANSCRIPT_REQUEST_HISTORY,
  FETCH_TRANSCRIPT_REQUEST_TYPES,
  FETCH_VACATION_COVERAGE,
  FETCH_WITHDRAW_REASONS,
  FORCE_CONCLUDE_STUDENT_COURSE,
  GET_INSTRUCTOR_PAYROLL,
  GET_INSTRUCTOR_PAYROLL_EXPORT,
  LOADING,
  LOGOUT,
  MASQUERADE_ADMIN,
  MERGE_STUDENT,
  NOT_LOADING,
  PATCH_PARTNER_SCHOOL,
  REMOVE_ETTS_TRANSCRIPT,
  REMOVE_FETCH,
  REMOVE_KNOWN_SCHOOL,
  REMOVE_STUDENT,
  REMOVE_UNKNOWN_SCHOOL,
  S3_FILE_DOWNLOAD,
  S3_FILE_DOWNLOAD_FAILED,
  S3_PRE_AUTH_DOWNLOAD,
  SEND_ELECTRONIC_TRANSCRIPT_TRANSFER_FILE_NOTIFICATION,
  SET_ACTIVE_CAMPUSES_ROSTER,
  SET_ADMINS,
  SET_ADMIN_PERMS,
  SET_API_DOCS,
  SET_ASSIGNABLE_COURSE_INSTRUCTORS,
  SET_ASSIGNMENT_VIOLATION_REPORT,
  SET_AUTHORIZATION_CODE,
  SET_AUTHORIZATION_CODES,
  SET_COGNITO_STUDENT_INFO,
  SET_COLUMN_TABLE_NAMES,
  SET_COURSES,
  SET_COURSES_FOR_STUDENT,
  SET_COURSE_ENROLLMENT_REPORT,
  SET_CURRENT_ADMIN,
  SET_ELECTRONIC_TRANSCRIPTS,
  SET_ELECTRONIC_TRANSCRIPT_SCHOOLS,
  SET_ENROLLMENT_REFUND_ELIGIBILITY,
  SET_FORCE_CONCLUDE_STUDENT_COURSE_COMPLETED_FALSE,
  SET_GET_S3_PRE_AUTH_LINK,
  SET_GRADES_FOR_STUDENT,
  SET_GRADE_CHANGE_OPEN_REVIEW,
  SET_INSTITUTIONS,
  SET_INSTITUTION_CAMPUSES_LABELS,
  SET_INSTITUTION_CAMPUS_TYPES,
  SET_INSTITUTION_PARTNER_AGREEMENTS,
  SET_INSTRUCTORS,
  SET_INSTRUCTOR_ASSIGNMENT_RULES,
  SET_KNOWN_SCHOOLS,
  SET_LABELS,
  SET_LOW_GRADE_WARNINGS,
  SET_MULTIMEDIA_FILES,
  SET_OBSERVERS,
  SET_OBSERVER_INSTITUTION_PARTNER_AGREEMENTS,
  SET_PARTNER_AGREEMENT_TYPES,
  SET_PARTNER_STUDENT_IMPORT,
  SET_PREVIOUS_RECORDS_FOR_STUDENT,
  SET_PUT_S3_PRE_AUTH_LINK,
  SET_REFUNDS,
  SET_REGISTRATIONS,
  SET_REGISTRATION_COUNTS,
  SET_SECURE_FILES,
  SET_STUDENT_PROGRESS_DATA,
  SET_SYLLABI_LINKS,
  SET_TERMS,
  SET_UNKNOWN_SCHOOLS,
  SET_UPLOADED_S3_ELECTRONIC_TRANSCRIPT,
  SET_UPLOADED_S3_SECURE_FILE,
  SET_VIOLATIONS,
  SET_VIOLATION_TYPES,
  UPDATE_ACADEMIC_SCHOLARSHIP_ENTRY,
  UPDATE_FETCH,
  UPDATE_INSTRUCTOR_ASSIGNMENT_RULES,
  UPDATE_MULTIMEDIA_CONTRACT_EDITOR_DIRECTORIES,
  UPDATE_REFUNDS,
  UPDATE_STUDENT,
  UPDATE_STUDENT_TRANSCRIPT_REQUEST,
} from './actions';
import {
  combineReducers,
} from 'redux';
import moment from 'moment';

function admin(state = null, action) {
  switch (action.type) {
  case SET_CURRENT_ADMIN:
    return action.payload.admin;
  case MASQUERADE_ADMIN: {
    return action.admin;
  }
  default:
    return state;
  }
}

function assignableCourseInstructors(state = {}, action) {
  switch (action.type) {
  case SET_ASSIGNABLE_COURSE_INSTRUCTORS:
    return {...state, [action.payload.course_id]: action.payload.instructors};
  default:
    return state;
  }
}

function instructorAssignmentRules(state = {}, action) {
  switch (action.type) {
  case SET_INSTRUCTOR_ASSIGNMENT_RULES: {
    const rules = {...state};
    action.payload.instructor_assignment_rules.forEach((rule) => {
      rules[rule.id] = rule;
    });
    return rules;
  }
  case UPDATE_INSTRUCTOR_ASSIGNMENT_RULES: {
    return {
      ...state,
      [action.payload.instructor_assignment_rule.id]: action.payload.instructor_assignment_rule,
    };
  }
  default:
    return state;
  }
}

function courses(state = {}, action) {
  switch (action.type) {
  case SET_COURSES: {
    const coursesById = {};
    action.payload.courses.forEach((course) => {
      coursesById[course.id] = course;
    });
    return coursesById;
  }
  default:
    return state;
  }
}

function authorizationCodes(state = {}, action) {
  switch (action.type) {
  case SET_AUTHORIZATION_CODES: {
    const codes = {};
    action.payload.authorization_codes.forEach((code) => {
      codes[code.id] = code;
    });
    return codes;
  }
  case SET_AUTHORIZATION_CODE:
    return {...state, [action.payload.code.id]: action.payload.code};
  default:
    return state;
  }
}

function knownSchools(state = {}, action) {
  switch (action.type) {
  case SET_KNOWN_SCHOOLS: {
    const schools = {};
    action.payload.schools.forEach((school) => {
      schools[school.id] = school;
    });
    return schools;
  }
  case REMOVE_KNOWN_SCHOOL: {
    const {[`${action.payload.school.id}`]: _schoolToRemove, ...rest} = state;
    return rest;
  }
  case ADD_KNOWN_SCHOOL: {
    return {
      ...state,
      [action.payload.school.id]: action.payload.school,
    };
  }
  default:
    return state;
  }
}

function unknownSchools(state = {}, action) {
  switch (action.type) {
  case SET_UNKNOWN_SCHOOLS: {
    const schools = {};
    action.payload.schools.forEach((school) => {
      schools[school.id] = school;
    });
    return schools;
  }
  case REMOVE_UNKNOWN_SCHOOL: {
    const {[`${action.payload.original_school_id}`]: _schoolToRemove, ...rest} = state;
    return rest;
  }
  default:
    return state;
  }
}

function admins(state = {}, action) {
  switch (action.type) {
  case SET_ADMINS: {
    const admins = {};
    for (const admin of action.payload.admins) {
      admins[admin.id] = admin;
    }
    return admins;
  }
  case SET_ADMIN_PERMS: {
    const newAdmins = {...state};
    newAdmins[action.payload.admin_id].perms = action.payload.perms;
    return newAdmins;
  }
  default:
    return state;
  }
}

function apiDocs(state = [], action) {
  switch (action.type) {
  case SET_API_DOCS:
    return action.payload.api_docs;
  default:
    return state;
  }
}

function grades(state = {}, action) {
  switch (action.type) {
  case SET_GRADES_FOR_STUDENT: {
    return {
      ...state,
      [action.payload.student_id]: action.payload.grades_by_enrollment,
    };
  }
  default:
    return state;
  }
}

function studentCognitoInfo(state = {}, action) {
  switch (action.type) {
  case SET_COGNITO_STUDENT_INFO: {
    return action.payload;
  }
  default:
    return state;
  }
}

function studentCourses(state = {}, action) { // @TODO: rename to enrollments
  switch (action.type) {
  case SET_COURSES_FOR_STUDENT: {
    return {
      ...state,
      [action.payload.student_id]: action.payload.courses,
    };
  }
  default:
    return state;
  }
}

function withdrawReasons(state = {}, action) {
  switch (action.type) {
  case FETCH_WITHDRAW_REASONS: {
    return action.payload.withdraw_reasons;
  }
  default:
    return state;
  }
}

function portageRegistrationData(state = {}, action) {
  switch (action.type) {
  case ADD_REGISTRATION_DATA: {
    return {
      ...action.payload.registrations.portage,
    };
  }
  default:
    return state;
  }
}

function nabcRegistrationData(state = {}, action) {
  switch (action.type) {
  case ADD_REGISTRATION_DATA: {
    return {
      ...action.payload.registrations.nabc,
    };
  }
  default:
    return state;
  }
}

function studentsPreviousRecords(state = {}, action) {
  switch (action.type) {
  case SET_PREVIOUS_RECORDS_FOR_STUDENT: {
    return {
      ...state,
      [action.payload.student_id]: action.payload.previous_records,
    };
  }
  default:
    return state;
  }
}

function students(state = {}, action) {
  switch (action.type) {
  case ADD_STUDENTS: {
    const students = {};
    action.payload.students.forEach((student) => {
      students[student.id] = student;
    });
    return {...state, ...students};
  }
  case UPDATE_STUDENT: {
    return {
      ...state,
      [action.payload.student.id]: action.payload.student,
    };
  }
  case REMOVE_STUDENT: {
    const {[`${action.studentId}`]: _studentToDelete, ...rest} = state;
    return rest;
  }

  case EXTEND_STUDENT_QUIZ_TIME: {
    return {
      ...state,
      [action.payload.student.id]: action.payload.student,
      message: action.payload.message,
    };
  }
  default:
    return state;
  }
}

function unassignedStudentIds(state = [], action) {
  switch (action.type) {
  case ADD_UNASSIGNED_STUDENT_IDS: {
    return [...action.payload.unassigned_students];
  }
  default:
    return state;
  }
}

function apiMergeStudent(state = {}, action) {
  switch (action.type) {
  case CLEAR_MERGE_STUDENT_FLAG: {
    return {
      ...state,
      merged: false,
    };
  }
  case MERGE_STUDENT: {
    return {
      ...state,
      merged: action.payload.merged,
    };
  }
  default:
    return state;
  }
}

function refunds(state = {}, action) {
  switch (action.type) {
  case SET_REFUNDS: {
    const refunds = {};
    action.payload.refunds.forEach((refund) => {
      refunds[refund.id] = refund;
    });
    return refunds;
  }
  case UPDATE_REFUNDS: {
    return {
      ...state,
      [action.payload.refund.id]: action.payload.refund,
    };
  }
  default:
    return state;
  }
}

function registrations(state = {}, action) {
  switch (action.type) {
  case SET_REGISTRATIONS: {
    const registrations = {};
    action.payload.registrations.forEach((r) => {
      registrations[r.id] = r;
    });
    return registrations;
  }
  default:
    return state;
  }
}

function registrationCounts(state = {}, action) {
  switch (action.type) {
  case SET_REGISTRATION_COUNTS: {
    return action.payload;
  }
  default:
    return state;
  }
}

function syllabiLinks(state = [], action) {
  switch (action.type) {
  case SET_SYLLABI_LINKS:
    return action.payload.syllabi_links;
  default:
    return state;
  }
}

function multimediaFiles(state = {}, action) {
  switch (action.type) {
  case SET_MULTIMEDIA_FILES:
    return action.payload;
  case UPDATE_MULTIMEDIA_CONTRACT_EDITOR_DIRECTORIES: {
    const currState = {...state};
    Object.keys(action.payload).forEach((course) => {
      const newCourseDirectory = action.payload[course];
      if (currState[course].children) {
        updateMultiMediaContractEditorDirectory(currState[course], newCourseDirectory);
      }
    });
    return currState;
  }
  default:
    return state;
  }
}

function updateMultiMediaContractEditorDirectory(nodes, newNode) {
  nodes.children.forEach((node, index) => {
    if (node.id === newNode.id) {
      nodes.children[index].children = newNode.children;
    } else if (node.children) {
      updateMultiMediaContractEditorDirectory(node, newNode);
    }
  });
}

function secureFiles(state = {
  disability: [],
  military: [],
  personal: [],
  acceptance_letter: [],
}, action) {
  switch (action.type) {
  case SET_SECURE_FILES:
    return action.payload.files;
  case SET_UPLOADED_S3_SECURE_FILE: {
    const secureFiles = {...state};
    secureFiles[action.category].push(action.secureFileInfo);
    return secureFiles;
  }
  default:
    return state;
  }
}

function electronicTranscripts(state = {}, action) {
  switch (action.type) {
  case SET_ELECTRONIC_TRANSCRIPT_SCHOOLS:
    return action.payload.electronic_transcripts;
  case SET_UPLOADED_S3_ELECTRONIC_TRANSCRIPT: {
    const electronicTranscripts = {...state};
    electronicTranscripts[action.email].transcripts.push(action.electronicTranscriptInfo);
    return electronicTranscripts;
  }
  case CREATE_ELECTRONIC_TRANSCRIPT_TRANSFER_S3_COGNITO_ACCOUNT: {
    const electronicTranscripts = {...state};
    let email = '';
    Object.keys(action.payload.electronic_transcript_transfer_account).forEach((value) => {
      email = value;
    });
    electronicTranscripts[email] = action.payload.electronic_transcript_transfer_account[email];
    return electronicTranscripts;
  }
  case REMOVE_ETTS_TRANSCRIPT: {
    const newState = {...state};
    newState[action.payload.school_name].transcripts = newState[action.payload.school_name].transcripts.filter((transcript) => transcript.name !== action.payload.filename);
    return newState;
  }
  case SET_ELECTRONIC_TRANSCRIPTS: {
    const newState = {...state};
    newState[action.payload.electronic_transcripts.email].transcripts = action.payload.electronic_transcripts.transcripts;
    return newState;
  }
  default:
    return state;
  }
}

function electronicTranscriptTransferNotification(state = {}, action) {
  switch (action.type) {
  case SEND_ELECTRONIC_TRANSCRIPT_TRANSFER_FILE_NOTIFICATION: {
    return {
      ...state,
      [action.payload.email_value]: moment().format('lll'),
    };
  }
  default:
    return state;
  }
}

function s3Actions(state = {}, action) { // @TODO: this should be cleaned up a bit
  switch (action.type) {
  case S3_PRE_AUTH_DOWNLOAD: {
    return {
      s3DownloadPreAuthURL: action.payload.pre_auth_url,
      s3DownloadPreAuthReceived: true,
      filename: action.payload.filename,
    };
  }
  case S3_FILE_DOWNLOAD: {
    const reader = new FileReader();
    reader.addEventListener('loadend', function() {
      const base64String = reader.result;
      const blob = new Blob([_s2ab(atob(base64String.split(',')[1]))], {type: action.contentType});
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = action.filename;
      a.click();
    }, false);
    reader.readAsDataURL(action.file);
    return {
      ...state,
      s3DownloadPreAuthURL: '',
      s3DownloadPreAuthReceived: false,
    };
  }
  case S3_FILE_DOWNLOAD_FAILED: {
    return {
      ...state,
      s3DownloadPreAuthURL: '',
      s3DownloadPreAuthReceived: false,
    };
  }
  case SET_PUT_S3_PRE_AUTH_LINK: {
    return {
      ...state,
      [action.meta.uuid]: {
        s3UploadPreAuthURL: action.payload.pre_auth_url,
        s3UploadPreAuthReceived: true,
      },
    };
  }
  default:
    return state;
  }
}

function apiGetS3ShareLink(state = {}, action) { // @TODO: combine with s3Actions
  switch (action.type) {
  case SET_GET_S3_PRE_AUTH_LINK: {
    return {
      s3DownloadPreAuthURL: action.payload.pre_auth_url,
    };
  }
  case CLEAR_S3_SHARE_LINK: {
    return {};
  }
  default:
    return state;
  }
}

function fetches(state = {}, action) {
  switch (action.type) {
  case FETCH_REQUEST:
    return {
      ...state,
      [action.meta.requestId]: {
        ...FETCH_REQUEST_OBJECT,
        ...action.meta,
        isRead: true, // don't need to toast this, just show to devs in header
        message: 'In flight',
      },
    };
  case FETCH_ERROR: {
    const message = (!action.payload || !action.payload.message || action.payload.message === 'Failed to fetch') ? 'An error has occurred, please try again. If the error continues, please contact support.' : action.payload.message;
    return {
      ...state,
      [action.meta.requestId]: {
        ...FETCH_ERROR_OBJECT,
        ...action.meta,
        message: action.meta.skipMessage ? '' : message,
        isRead: false,
      },
    };
  }
  case REMOVE_FETCH: {
    const {[action.requestId]: _omit, ...otherFetches} = state;
    return otherFetches;
  }
  case UPDATE_FETCH: {
    return {
      ...state,
      [action.fetchRequest.requestId]: {
        ...action.fetchRequest,
      },
    };
  }
  default: { // TODO: Fetch success should have it's own case
    if (action.meta && action.meta.requestId) {
      // this is a successful fetch...update the status
      return {
        ...state,
        [action.meta.requestId]: {
          ...FETCH_SUCCESS_OBJECT,
          ...action.meta,
          message: (action.meta.responseStatus === 200 && action.payload === undefined) ? 'Success' : action.payload.message,
          isSuccessfullFetch: (action.meta.responseStatus === 200 && action.payload === undefined) ? true : action.payload.status === 'success',
          isRead: false,
        },
      };
    }
    return state;
  }
  }
}

function loadingStates(state = {}, action) {
  switch (action.type) {
  case LOADING: {
    return {
      ...state,
      [action.resourceLoading]: true,
    };
  }
  case NOT_LOADING: {
    return {
      ...state,
      [action.resourceNotLoading]: false,
    };
  }
  default: {
    return state;
  }
  }
}

function _s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}

function transcriptRequests(state = {}, action) {
  switch (action.type) {
  case CLEAR_TRANSCRIPT_REQUESTS: {
    return {};
  }
  case FETCH_TRANSCRIPT_REQUESTS: {
    const transcriptRequests = {};
    action.payload.requests.forEach((request) => {
      transcriptRequests[request.transcript_request_id] = request;
    });
    return {
      ...state,
      ...transcriptRequests,
    };
  }
  case UPDATE_STUDENT_TRANSCRIPT_REQUEST: {
    return {
      ...state,
      [Object.keys(state).filter((key) => state[key].transcript_request_id === action.payload.student_transcript_request.transcript_request_id)]: action.payload.student_transcript_request,
    };
  }
  default:
    return state;
  }
}

function studentForceConcludePoints(state = {
  nabc: {},
  canvas: {},
  completed: false,
}, action) {
  switch (action.type) {
  case FETCH_NABC_CANVAS_COURSE_POINTS: {
    return {
      ...state,
      nabc: action.payload.nabc_final_points,
      canvas: action.payload.canvas_final_points,
    };
  }
  case FORCE_CONCLUDE_STUDENT_COURSE: {
    return {
      ...state,
      completed: true,
    };
  }
  case CLEAR_FORCE_CONCLUDE_STUDENT_COURSE_STATE: {
    return {
      nabc: {},
      canvas: {},
      completed: false,
    };
  }
  case SET_FORCE_CONCLUDE_STUDENT_COURSE_COMPLETED_FALSE: {
    return {
      ...state,
      completed: false,
    };
  }
  default:
    return state;
  }
}

function refundEligibility(state = {}, action) {
  switch (action.type) {
  case SET_ENROLLMENT_REFUND_ELIGIBILITY: {
    return {
      ...action.payload.refund_eligibility,
    };
  }
  default:
    return state;
  }
}

function transcriptRequestHistory(state = {request_history: []}, action) {
  switch (action.type) {
  case FETCH_TRANSCRIPT_REQUEST_HISTORY: {
    return {
      request_history: action.payload.transcript_history_list,
    };
  }
  case CLEAR_TRANSCRIPT_HISTORY: {
    return {
      request_history: [],
    };
  }
  default:
    return state;
  }
}

function terms(state = {}, action) {
  switch (action.type) {
  case SET_TERMS: {
    return action.payload.terms;
  }
  default:
    return state;
  }
}

function allCampuses(state = {}, action) {
  switch (action.type) {
  case SET_ACTIVE_CAMPUSES_ROSTER: {
    return action.payload.campus_list;
  }
  default:
    return state;
  }
}

function activeCampusesWithProgressReportStudents(state = {}, action) {
  switch (action.type) {
  case CAMPUSES_WITH_PROGRESS_REPORT_STUDENTS: {
    return action.payload.campus_list;
  }
  default:
    return state;
  }
}

function fetchStudentProgressData(state = [], action) {
  switch (action.type) {
  case SET_STUDENT_PROGRESS_DATA: {
    return action.payload.student_progress_data;
  }
  default:
    return state;
  }
}

function lowGradeWarnings(state = [], action) {
  switch (action.type) {
  case SET_LOW_GRADE_WARNINGS: {
    return action.payload.low_grade_warnings;
  }
  default:
    return state;
  }
}

function fetchPartnerSchools(state = {}, action) {
  switch (action.type) {
  case FETCH_PARTNER_SCHOOLS: {
    return action.payload.partner_schools;
  }
  default:
    return state;
  }
}

function createPartnerSchool(state = {}, action) {
  switch (action.type) {
  case CREATE_PARTNER_SCHOOL: {
    return action.payload;
  }
  default:
    return state;
  }
}

function patchPartnerSchool(state = {}, action) {
  switch (action.type) {
  case PATCH_PARTNER_SCHOOL: {
    return action.payload.partner_schools;
  }
  default:
    return state;
  }
}

function courseEnrollmentReport(state = {}, action) {
  switch (action.type) {
  case SET_COURSE_ENROLLMENT_REPORT: {
    return action.payload.data;
  }
  default:
    return state;
  }
}

function tableColumnNames(state = [], action) {
  switch (action.type) {
  case SET_COLUMN_TABLE_NAMES: {
    return action.payload.column_names;
  }
  default:
    return state;
  }
}

function integrityViolations(state = {}, action) {
  switch (action.type) {
  case SET_VIOLATIONS: {
    const integrityViolations = {};
    action.payload.violations.forEach((violation) => {
      integrityViolations[violation.id] = violation;
    });
    return {...state, ...integrityViolations};
  }
  default:
    return state;
  }
}

function assignmentViolationReport(state = [], action) {
  switch (action.type) {
  case SET_ASSIGNMENT_VIOLATION_REPORT: {
    return action.payload.assignment_violations;
  }
  default:
    return state;
  }
}

function instructorPayroll(state = [], action) {
  switch (action.type) {
  case GET_INSTRUCTOR_PAYROLL: {
    return action.payload.data;
  }
  default:
    return state;
  }
}

function integrityViolationTypes(state = {}, action) {
  switch (action.type) {
  case SET_VIOLATION_TYPES: {
    return action.payload.integrity_violation_types;
  }
  default:
    return state;
  }
}

function instructors(state = {}, action) {
  switch (action.type) {
  case SET_INSTRUCTORS: {
    return action.payload.instructors;
  }
  default:
    return state;
  }
}

function instructorPayrollExport(state = [], action) {
  switch (action.type) {
  case GET_INSTRUCTOR_PAYROLL_EXPORT: {
    return null;
  }
  default:
    return state;
  }
}

function partnerStudents(state = [], action) {
  switch (action.type) {
  case SET_PARTNER_STUDENT_IMPORT: {
    return action.payload.students_to_import;
  }
  default:
    return state;
  }
}

function observers(state = {}, action) {
  switch (action.type) {
  case SET_OBSERVERS: {
    return action.payload.observers;
  }
  default:
    return state;
  }
}

function partnerAgreements(state = {}, action) {
  switch (action.type) {
  case SET_INSTITUTION_PARTNER_AGREEMENTS: {
    return action.payload.partner_agreements;
  }
  default:
    return state;
  }
}

function observerPartnerAgreements(state = {}, action) {
  switch (action.type) {
  case SET_OBSERVER_INSTITUTION_PARTNER_AGREEMENTS: {
    return action.payload.partner_agreements;
  }
  default:
    return state;
  }
}

function activeEnrollments(state = {}, action) {
  switch (action.type) {
  case FETCH_ACTIVE_ENROLLMENTS_FOR_INSTRUCTOR: {
    return action.payload.active_students;
  }
  default:
    return state;
  }
}

function vacationCoverage(state = {}, action) {
  switch (action.type) {
  case FETCH_VACATION_COVERAGE: {
    return action.payload.vacation_coverage;
  }
  default:
    return state;
  }
}

function blueprints(state = {}, action) {
  switch (action.type) {
  case FETCH_BLUEPRINT_COURSES: {
    return action.payload.blueprints;
  }
  case FETCH_BLUEPRINT_COURSES_BY_COURSE_CODE: {
    const blueprints = {};
    Object.values(action.payload.blueprints).forEach((blueprint) => {
      blueprints[blueprint.canvas_course_id] = blueprint;
    });
    return {
      ...state,
      ...blueprints,
    };
  }
  default:
    return state;
  }
}

function academicScholarships(state = {}, action) {
  switch (action.type) {
  case FETCH_ACADEMIC_SCHOLARSHIP_REPORT: {
    return action.payload.scholarships;
  }
  case UPDATE_ACADEMIC_SCHOLARSHIP_ENTRY: {
    const scholarship = JSON.parse(action.payload.scholarship);
    return {
      ...state,
      [scholarship.student_id]: {
        ...scholarship,
      },
    };
  }
  default:
    return state;
  }
}

function departmentChairCourses(state = [], action) {
  switch (action.type) {
  case FETCH_COURSES_FOR_DEPT_CHAIR: {
    return action.payload.courses;
  }

  default:
    return state;
  }
}

function transcriptRequestTypes(state = {}, action) {
  switch (action.type) {
  case FETCH_TRANSCRIPT_REQUEST_TYPES:
    return action.payload.request_types;

  default:
    return state;
  }
}

function partnerAgreementTypes(state = {}, action) {
  switch (action.type) {
  case SET_PARTNER_AGREEMENT_TYPES: {
    return action.payload.partner_agreement_types;
  }
  default:
    return state;
  }
}

function institutions(state = {}, action) {
  switch (action.type) {
  case SET_INSTITUTIONS: {
    return action.payload.institutions;
  }
  default:
    return state;
  }
}

function institutionCampusTypes(state = {}, action) {
  switch (action.type) {
  case SET_INSTITUTION_CAMPUS_TYPES: {
    return action.payload.institution_campus_types;
  }
  default:
    return state;
  }
}

function institutionCampusesLabels(state = {}, action) {
  switch (action.type) {
  case SET_INSTITUTION_CAMPUSES_LABELS: {
    return action.payload.institution_campuses_labels;
  }
  default:
    return state;
  }
}

function labels(state = {}, action) {
  switch (action.type) {
  case SET_LABELS: {
    return action.payload.labels;
  }
  default:
    return state;
  }
}

function gradeChangeOpenReviewReport(state = [], action) {
  switch (action.type) {
  case SET_GRADE_CHANGE_OPEN_REVIEW: {
    return action.payload.grade_change_records;
  }
  default:
    return state;
  }
}

const appReducer = combineReducers({
  activeEnrollments,
  academicScholarships,
  allCampuses,
  activeCampusesWithProgressReportStudents,
  admin,
  admins,
  apiDocs,
  apiGetS3ShareLink,
  apiMergeStudent,
  assignableCourseInstructors,
  assignmentViolationReport,
  authorizationCodes,
  blueprints,
  courseEnrollmentReport,
  courses,
  createPartnerSchool,
  departmentChairCourses,
  fetchStudentProgressData,
  electronicTranscripts,
  electronicTranscriptTransferNotification,
  fetches,
  fetchPartnerSchools,
  grades,
  gradeChangeOpenReviewReport,
  institutions,
  institutionCampusesLabels,
  institutionCampusTypes,
  instructors,
  instructorAssignmentRules,
  integrityViolations,
  integrityViolationTypes,
  knownSchools,
  labels,
  loadingStates,
  lowGradeWarnings,
  multimediaFiles,
  nabcRegistrationData,
  observers,
  observerPartnerAgreements,
  instructorPayroll,
  instructorPayrollExport,
  partnerAgreements,
  partnerAgreementTypes,
  partnerStudents,
  patchPartnerSchool,
  portageRegistrationData,
  refundEligibility,
  refunds,
  registrationCounts,
  registrations,
  s3Actions,
  secureFiles,
  studentCognitoInfo,
  studentCourses,
  studentForceConcludePoints,
  students,
  studentsPreviousRecords,
  syllabiLinks,
  tableColumnNames,
  terms,
  transcriptRequestHistory,
  transcriptRequests,
  transcriptRequestTypes,
  unassignedStudentIds,
  unknownSchools,
  vacationCoverage,
  withdrawReasons,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
